import uikit from "./uikit.js"
import components from "./components.js"
// import Swup from "swup"
import Headroom from "headroom.js"
import Swiper, {
  Navigation,
  Pagination,
  Thumbs,
  EffectCoverflow,
  Autoplay,
} from "swiper"
import "swiper/css"

uikit()
components()
function uikitPageDropdown() {
  $(".js-uikit-pages-dropdown").on("click", (e) => {
    $(".front-ui-dropdown").toggleClass("active")
  })
}
uikitPageDropdown()

var app = {
  headroomFn() {
    var header = document.querySelector("header")
    var options = {
      offset: {
        up: 100,
        down: 50,
      },
      tolerance: {
        up: 5,
        down: 0,
      },
      classes: {
        initial: "header",
        pinned: "header--pinned",
        unpinned: "header--unpinned",
        top: "header--top",
        notTop: "header--not-top",
        bottom: "header--bottom",
        notBottom: "header--not-bottom",
        frozen: "header--frozen",
      },
      onPin: function () {},
      onUnpin: function () {},
      onTop: function () {},
      onNotTop: function () {},
      onBottom: function () {},
      onNotBottom: function () {},
    }
    var headroom = new Headroom(header, options)
    headroom.init()
  },
  headerMenuButtonFn() {
    var headerMenuBtn = document.querySelector(".header__nav__menu")
    headerMenuBtn.addEventListener("click", function () {
      if (headerMenuBtn.classList.contains("opened")) {
        headerMenuBtn.classList.remove("opened")
      } else {
        headerMenuBtn.classList.add("opened")
      }
    })
    const menuBtn = document.querySelector(".js-menu-button")
    const menu = document.querySelector(".js-menu")
    const header = document.querySelector(".header")

    menuBtn.addEventListener("click", function () {
      this.classList.toggle("opened")
      menu.classList.toggle("opened")
      header.classList.toggle("menu-opened")
      document.querySelector("html").classList.toggle("menu-opened")
      document.querySelector("body").classList.toggle("menu-opened")
    })
  },
  heroSliderFn() {
    const heroBgSwiper = new Swiper(".js-hero-bg-slider", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 0,
      speed: 700,
      modules: [EffectCoverflow],

      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },
    })

    const heroSwiper = new Swiper(".js-hero-slider", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: "100%",
      speed: 700,
      modules: [Navigation, Pagination, Thumbs, EffectCoverflow, Autoplay],

      autoplay: {
        delay: 5000,
      },

      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },

      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },

      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      thumbs: {
        swiper: heroBgSwiper,
      },
    })
  },
  aboutReferenceSliderFn() {
    const aboutReferenceSwiper = new Swiper(".js-about-reference-slider", {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 20,
      speed: 700,
      modules: [Navigation, EffectCoverflow, Autoplay],

      autoplay: {
        delay: 5000,
      },

      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },

      navigation: {
        nextEl: ".js-about-reference-slider  .swiper-button-next",
        prevEl: ".js-about-reference-slider  .swiper-button-prev",
      },
    })
  },
  lessonsSliderFn() {
    const lessonsSwiper = new Swiper(".js-lessons-slider", {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 40,
      speed: 700,

      modules: [EffectCoverflow],
      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },

      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1.5,
        },
        1200: {
          slidesPerView: 2,
        },
      },
    })
  },
  newsSliderFn() {
    const newsSwiper = new Swiper(".js-news-slider", {
      loop: true,
      slidesPerView: 2,
      spaceBetween: 40,
      speed: 700,

      modules: [EffectCoverflow],
      effect: "coverflow",
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 1,
        slideShadows: false,
      },
      breakpoints: {
        320: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 1.5,
        },
        1200: {
          slidesPerView: 2,
        },
      },
    })
  },

  load() {
    console.log("load")
  },
  resized() {
    console.log("resized")
  },

  init: function () {
    app.load()
    app.headroomFn()
    app.heroSliderFn()
    app.aboutReferenceSliderFn()
    app.lessonsSliderFn()
    app.newsSliderFn()
    app.headerMenuButtonFn()
  },
}

function docReadied(fn) {
  window.addEventListener("DOMContentLoaded", fn)
}
function docResized(fn) {
  window.addEventListener("resize", fn)
}
docReadied(() => {
  app.init()
})

docResized(() => {
  app.resized()
})

export default { docResized, docReadied }
